import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { catchError, map, pluck, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { IAllAssetBuildings } from '@app/pages/settings/portfolio/interfaces/area-assets.interfaces';
import { AssetBuildingNameQuery } from '@app/pages/settings/portfolio/graphql/assets-graphql.queries';

// TODO Make this resolver generic and move it to global resolvers folder
@Injectable()
export class AssetBuildingNameResolver {
	constructor(private apollo: Apollo) {}

	resolve(snapshot: ActivatedRouteSnapshot) {
		return this.apollo
			.query<IAllAssetBuildings>({
				query: AssetBuildingNameQuery,
				variables: {
					objectId: [snapshot.params.id],
				},
			})
			.pipe(
				take(1),
				pluck('data', 'buildings'),
				map(buildings => {
					if (buildings.length === 0) {
						return '';
					}
					return buildings[0].name;
				}),
				catchError(err => {
					console.error(err.networkError);
					return EMPTY;
				})
			);
	}
}
